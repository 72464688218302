/* ion-content ion-toolbar {
  --background: translucent;
} */

.login-container {
  width: 330px;
  max-width: 400px;
  justify-content: center;
  margin: 2px;
  margin: auto;
}
.login-header {
  /* display: flex; */
  margin-top: 200px;
  padding: 16px;
  background-color: #c0392b;
  border-radius: 5px;
  color: white;
  justify-content: center;
}

.login-title {
  margin-top: 8px;
  position: relative;
  text-align: center;
  font-weight: 700;
  color: white;
  letter-spacing: 2px;
  font-size: 30px;
}

.login-subtitle {
  color: white;
  text-align: center;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 12px;
}

.login-content {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #34495e;
  border-radius: 5px;
}

.login-button {
  border: none;
  border-radius: 3px;
  /* background-color: rgb(96, 148, 0); */
  background-color: #34495e;
  color: white;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  display: block;
}
.fields {
  width: 100%;
  /* padding: 75px 5px 5px 5px; */
}
.fields input {
  width: 70%;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  color: white;
  letter-spacing: 5px;
  padding: 5px;
}

.fields svg {
  height: 22px;
  width: 22px;
  /* margin: 0 10px -3px 25px; */
  margin: 0 10px -3px 25px;
}
