.progress-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  /* height: 50px; */
  margin-top: 3px;
  font-family: Arial, sans-serif;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background: #e0e0e0;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  /* background: linear-gradient(90deg, #4caf50, #81c784); */
  background: darkgray;
  border-radius: 2px;
  transition: width 0.4s ease-in-out;
}

.progress-indicator {
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  background: #ffffff;
  color: #4caf50;
  font-weight: bold;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 5px;
  border: 1px solid #4caf50;
  white-space: nowrap;
  transition: left 0.4s ease-in-out;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 1px;
  font-size: 10px;
  font-weight: 800;
  color: #666;
}
