ion-header {
  background-color: #c0392b;
}

/* 
body {
  background-color: #c0392b;
}

html {
  background-color: #c0392b;
} */

ion-toolbar {
  --background: #c0392b;
}

#cry {
  --background: #c0392b;
}

.crypto-header {
  /* margin-top: 32px; */
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 4px;
  background-color: #c0392b;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 20;
  box-shadow: 0px 4px 4px -3px rgba(105, 105, 105, 1);
}

.top-title {
  margin-top: 2px;
  margin-bottom: 1px;
  /* position: relative; */
  text-align: left;
  font-weight: 250;
  color: white;
  letter-spacing: 2px;
  font-size: 26px;
}

.account-subtitle {
  /* margin-top: s */
  margin-bottom: 5px;
  /* position: relative; */
  text-align: center;
  font-weight: 420;
  color: white;
  letter-spacing: 4px;
  font-size: 14px;
}

.account-info {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 5px;
  background-color: #34495e;
  border-radius: 5px;
}

.account-info-wrapper {
  display: grid;
  padding: 8px;
  grid-template-columns: repeat(1, 80%);
}

/* .account-info-title {
  margin-bottom: 2px;
  padding: 3px;
  border-radius: 5px;
  background-color: #a1a8af;
  text-align: center;
  font-weight: 450;
  color: black;
  letter-spacing: 4px;
  font-size: 20px;
} */

.account-info-item {
  padding: 2px;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.crypto-wrapper {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 2px;
  margin: 2px;
  margin-bottom: 6px;
  border: 0px;
  border-left: 4px;
  border-color: rgb(114, 114, 114);
  border-style: solid;
  border-radius: 4px;
}

.crypto-info-wrapper {
  display: grid;
  padding: 2px;
  margin: 2px;
  margin-left: 6px;
  grid-template-columns: 65% 35%;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

.crypto-title {
  margin-top: 1px;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
  font-weight: 350;
  color: rgb(104, 104, 104);
  letter-spacing: 1px;
  font-size: 18px;
}

.crypto-price {
  text-align: center;
  font-weight: 550;
  color: rgb(58, 58, 58);
  letter-spacing: 0.3px;
  font-size: 22px;
}

.crypto-name {
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  color: rgb(168, 168, 168);
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 8px;
}

.crypto-24h {
  /* text-align: left; */
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: 10px;
}

.crypto-info {
  display: grid;
  grid-template-columns: 35% 65%;
  /* float: none; */
  color: black;
  padding: 0px;
  margin: 0px;
  border-radius: 6px;
}
.crypto-info-item {
  background-color: rgb(163, 163, 163);
}
.crypto-info-item,
.crypto-info-item2 {
  background-color: rgb(234, 234, 234);
  font-size: 12px;
  padding: 1px;
  margin-left: 0px;
  margin-right: 2px;
  margin-bottom: 2px;
  /* margin: 2px; */
  border-radius: 5px;
}

.crypto-refresh {
  float: right;
  background-color: rgb(110, 17, 0);
  border-radius: 4px;
  color: white;
  width: 80px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
}

.crypto-profit-percent {
  font-size: 24px;
  color: rgb(206, 206, 206);
  font-weight: 500;
  text-align: center;
}

.crypto-roi {
  margin-top: 2px;
  font-size: 10px;
  color: rgb(134, 134, 134);
  text-align: center;
}

.crypto-broker,
.crypto-staking {
  margin-top: 2px;
  font-size: 10px;
  color: white;
  /* background-color: #2c3e50; */
  background-color: #4e4e4e;
  text-align: center;
  border-radius: 3px;
}

.crypto-staking {
  color: white;
  /* background-color: #888888; */
  /* background-color: hsl(93, 79%, 40%); */
  background-color: hsl(93, 67%, 38%);
}

.crypto-fiftyTwoWeekRange {
  margin-top: 2px;
  font-size: 10px;
  color: white;
  background-color: #7e7e7e;
  text-align: center;
  border-radius: 3px;
  letter-spacing: -0.5px;
  font-weight: 500;
  margin-bottom: 0px;
}

.crypto-privacy-mode {
  margin-top: 8px;
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 2px;
  letter-spacing: 0;
  padding-left: 8px;
  padding-right: 8px;
  float: left;
}

@keyframes blinker {
  10% {
    opacity: 0;
  }
}

.streaming-mode {
  animation: blinker 3s linear infinite;
  /* margin-top: 8px; */
  font-size: 10px;
  font-weight: 500;
  /* background-color: rgba(0, 0, 0, 0.1); */
  text-align: center;
  border-radius: 2px;
  letter-spacing: 0;
  /* padding-left: 8px; */
  /* padding-right: 8px; */

  float: left;
}

.crypto-news {
  display: grid;
  grid-template-columns: 95% 5%;
  background-color: rgba(121, 121, 121, 0.1);
  padding: 5px;
  margin: 4px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
}

.crypto-news-title {
  font-size: 14px;
  padding-left: 4px;
  border: 0px;
  border-left: 2px;
  border-color: rgb(59, 59, 59);
  border-style: solid;
  /* border-radius: 1px; */
}

.crypto-news-icon {
  justify-content: right;
  /* color: white; */
  /* background-color: black; */
}

.crypto-top-stats {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1px;
  padding-left: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 4px;
  border-radius: 6px;
}

.crypto-top-total {
  width: 100%;
  margin-top: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
}

.crypto-top-roi {
  width: 100%;
  margin-top: 6px;
  padding: 0px;
  font-weight: 300;
  font-size: 28px;
}

.crypto-top-profits,
.crypto-top-invested {
  width: 100%;
  margin-top: 2px;
  margin-right: 0px;
  padding: 2px;
  /* text-align: right; */
}

.crypto-top-invested {
  text-align: left;
}

.crypto-top-coin-item {
  background-color: rgba(121, 121, 121, 0.1);
  font-size: 15px;
  font-weight: 350;
  padding: 4px;
  margin: 4px;
  border: 0px;
  border-left: 2px;
  border-color: rgb(59, 59, 59);
  border-style: solid;
}

.top-crypto-markcap {
  float: right;
  font-size: 14px;
  background-color: rgb(128, 128, 128);
  border-radius: 4px;
  color: white;
  padding: 2px;
  padding-right: 4px;
  padding-left: 4px;
}

.icons-top-wrapper {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: center;
}

.num-coins,
.num-last {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  /* letter-spacing: -0.1px; */
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
}

.num-last {
  border-right: 0px;
  /* border-style: none;
  border-color: white; */
}

.main-fire-chart {
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
  color: hsl(71, 100%, 28%);
}

.crypto-secondary-panel-wrapper {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* background-color: #476583; */
  background-color: #dddddd;
  /* background-color: #c0392b; */
  /* border-radius: 4px; */
  margin-bottom: 8px;
  padding: 0px;
  /* position: sticky;
  top: 90px;
  z-index: 20; */
}

.crypto-news-header {
  /* background-color: #2c3e50; */
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: #4e4e4e;
  color: white;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 450;
  /* border-radius: 4px; */
  margin: 3px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 3px;
  padding-bottom: 3px;
}

.crypto-news-header-inactive {
  color: rgb(119, 119, 119);
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 3px;
  text-align: center;
  text-transform: uppercase;
  border: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.crypto-fav-home {
  margin-left: 8px;
  margin-right: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /* justify-content: "center"; */
  /* text-align: "center"; */
  /* width: max-content; */
}
