/* teko-regular - latin */
@font-face {
  font-family: "Teko";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/teko-v15-latin-regular.eot");
  src: local(""), url("../fonts/teko-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/teko-v15-latin-regular.woff2") format("woff2"),
    url("../fonts/teko-v15-latin-regular.woff") format("woff"), url("../fonts/teko-v15-latin-regular.ttf") format("truetype"),
    url("../fonts/teko-v15-latin-regular.svg#Teko") format("svg");
}

ion-toolbar {
  --background: #34495e;
  --color: white;
}

#sto {
  --background: #34495e;
}

ion-content {
  /* --background: rgb(249, 249, 249); */
}

ion-tab-bar {
  --color: #6e6e6e;
  --background: rgb(242, 242, 242);
  --color-selected: #233140;
}

.stocks-header {
  /* margin-top: 32px; */
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 4px;
  padding-bottom: 14px;
  background-color: #34495e;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0px 4px 4px -3px rgba(105, 105, 105, 1);
}

.top-title {
  margin-top: 2px;
  margin-bottom: 1px;
  text-align: left;
  font-weight: 250;
  color: white;
  letter-spacing: 2px;
  font-size: 26px;
  /* font-family: teko-regular; */
}

.stocks-privacy-mode {
  margin-top: 8px;
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 2px;
  letter-spacing: 0;
  padding-left: 8px;
  padding-right: 8px;
  float: left;
}

.net-liquidity {
  width: 100%;
  margin-top: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
}

.stocks-cash-balance {
  width: 100%;
  margin-top: 6px;
  padding: 0px;
  font-weight: 300;
  font-size: 24px;
}

.stock-details-header-sticky {
  position: sticky;
  left: 0;
  z-index: 2;
}

.stock-details-header,
.stock-details-header-left {
  background-color: rgb(235, 235, 235);
  color: #646464;
  font-size: 11px;
  font-weight: 400;
  padding: 3px;
  text-transform: uppercase;
  padding-bottom: 6px;
  border-top: solid;
  border-width: 1px;
}

.stock-details-header-active,
.stock-details-header-active-left {
  color: black;
  font-weight: 600;
  font-size: 11px;
  padding: 3px;
  text-transform: uppercase;
  padding-bottom: 6px;
  border-bottom: 2px dashed #757575;
  text-decoration: none;
}

.stock-details-header-left,
.stock-details-header-active-left {
  text-align: left;
}

.stock-details-item,
.stock-details-item-symbol,
.stock-details-item-pnl {
  font-size: 15px;
  margin: 2px;
  padding: 1px;
  border: 0px;
  border-bottom: 1px;
  border-color: rgb(242, 242, 242);
  border-style: solid;
}

.stock-details-item-symbol {
  position: sticky;
  min-width: 60px;
  left: 0;
  z-index: 2;
  background-color: white;
}

.stock-details-item-symbol-header,
.stock-details-item-symbol-header-active {
  font-size: 11px;
  position: sticky;
  /* min-width: 50px; */
  left: 0;
  z-index: 2;
  background-color: rgb(213, 213, 213);
  /* color: white; */
}

.stock-details-item-pnl-header,
.stock-details-item-pnl-header-active {
  font-size: 11px;
  position: sticky;
  /* min-width: 50px; */
  right: 0;
  z-index: 2;
  /* color: white; */
  background-color: rgb(213, 213, 213);
}

.stock-details-item-symbol-header-active,
.stock-details-item-pnl-header-active {
  font-weight: 600;
  color: rgb(100, 100, 100);
  border-top: 2px dotted #b0b0b0;
  border-bottom: 2px dotted #b0b0b0;
  text-decoration: none;
}

.stock-details-item-pnl {
  position: sticky;
  right: 0;
  z-index: 2;
  padding-left: 2px;
  padding-right: 2px;
  background-color: white;
  border-color: lightgrey;
  border-width: 0px;
  border-left-style: dashed;
  border-left-width: 0.8px;
}

.stock-top-stats {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1px;
}

.stock-top-profits,
.stock-top-invested {
  width: 100%;
  margin-top: 2px;
  margin-right: 0px;
  padding: 2px;
  /* text-align: right; */
}

.stock-top-invested {
  text-align: left;
}

.stock-top-total {
  width: 100%;
  margin-top: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: -1px;
}

.stocks-top-invested,
.stocks-top-profits {
  width: 100%;
  margin-top: 2px;
  margin-right: 0px;
  padding: 2px;
}

.stock-details-wrap-the-wrapper {
  display: grid;
  grid-template-rows: 50% 50%;
}

.stock-details-wrapper {
  display: grid;
  grid-template-columns: 25% 20% 20% 15% 20%;
  padding: 0px;
  /* margin-left: 4px; */
  margin: 0px;
  font-size: 14px;
  text-align: right;
  background-color: rgb(247, 251, 255);
}

/* .stock-details-wrapper-info {

} */

.stocks-table-company {
  font-size: 8px;
  color: rgb(122, 122, 122);
  font-weight: 500;
}

.stocks-top-stats-two {
  background-color: white;
  display: grid;
  grid-template-columns: 39% 22% 39%;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  /* margin-bottom: 2px; */
  border-radius: 6px;
  border-style: solid;
  border-width: 0.1px;
  border-color: rgb(196, 196, 196);
  box-shadow: 0px 0px 0px grey;
  position: sticky;
  top: 82px;
  z-index: 20;
}

.stocks-top-stats-two-first {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  font-size: 26px;
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
  color: rgb(121, 121, 121);
  padding: 0px;
}

.stocks-top-stats-two-center {
  font-size: 26px;
  text-align: center;
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
  color: rgb(121, 121, 121);
  padding: 0px;
}

.stocks-top-stats-two-pnl {
  display: grid;
  grid-template-columns: 55% 45%;
  text-align: center;
  font-size: 26px;
  color: rgb(121, 121, 121);
  padding: 0px;
}

.stock-top-stats-title {
  margin-top: 0px;
  font-size: 9px;
  color: rgb(61, 61, 61);
  font-weight: 300;
  /* letter-spacing: 1px; */
}

.stock-top-stats-value {
  margin-top: 0px;
  /* margin-bottom: 10px; */
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.stock-main-table {
  width: 100%;
  margin: 0px;
}

.stock-main-table-wrapper {
  margin-left: 4px;
  margin-right: 4px;
  overflow-x: auto;
}

.stock-main-table-wrapper tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.stock-info-wrapper {
  background-color: rgb(248, 248, 248);
  display: grid;
  grid-template-columns: 35% 30% 35%;
  font-size: 12px;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.stock-high-low-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}

.stock-info-label {
  text-align: right;
  color: rgb(158, 158, 158);
  margin-right: 6px;
  font-weight: 400;
}

.stock-info-value {
  text-align: left;
  font-weight: 500;
}

.stock-info-separator {
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
  color: rgb(121, 121, 121);
}

.stock-info-type,
.stock-info-roi {
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.stock-info-roi {
  margin-top: 6px;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 1.5px;
}

.stock-info-type {
  background-color: rgb(102, 102, 102);
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 4px;
  font-size: 10px;
  padding: 1px;
}
.stock-info-company {
  margin-top: 6px;
  text-align: center;
  font-weight: 800;
  color: #40739e;
}

.stocks-market-status-wrapper {
  margin-top: 6px;
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: 35% 30% 35%;
  font-size: 10px;
  text-transform: uppercase;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
}

.stock-market-status-now {
  text-align: left;
  padding: 3px;
}

.stock-market-status-next {
  text-align: right;
  padding: 3px;
  margin-right: 6px;
}

.stock-market-middle-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.2);
}

.stock-performance-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.stock-search-input {
  padding: 1px;
  width: 50%;
  height: 22px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  font-size: 14px;
  letter-spacing: -0.6px;
}

.stock-options-item {
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;
  margin-left: 5px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.stock-indices-wrapper {
  text-align: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 0;
}

.stock-market-summary-wrapper {
  text-align: center;
  justify-content: "center";
  margin-top: 14px;
  margin-bottom: 8px;
  font-size: 11px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  text-transform: uppercase;
}

.stock-indices-item-upper {
  float: left;
  padding-top: 3px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-style: none;
  width: 100px;
}

.stock-indices-item-upper,
.stock-indices-item-bottom {
  background-color: rgb(245, 245, 245);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 300;
  margin-bottom: 0;
  padding: 0;
  padding-top: 4px;
}
.stock-indices-item-bottom {
  font-weight: 500;
  margin-left: 0;
  margin-right: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  padding-top: 2px;
  padding-bottom: 2px;
}

.stock-trades-header,
.stock-trades-item {
  font-size: 11.5px;
  border-bottom: solid;
  border-width: 0.1px;
  border-color: rgb(226, 226, 226);
  color: #2c3e50;
}

.stock-trades-header {
  font-weight: 500;
  font-size: 11px;
  background-color: rgb(243, 243, 243);
  text-align: left;
}

.stock-trades-title {
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
}

.stock-gainerslosers-wrapper {
  margin: 4px;
  display: grid;
  grid-template-columns: 50% 50%;
  /* padding-top: 8px;
  padding-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.1px;
  border-color: rgb(196, 196, 196);
  box-shadow: 0.5px 0.5px 1px grey; */
}

.stock-gainerslosers-item-left {
  float: left;
  border-width: 1.5px;
  border-color: lightgray;
  border-style: solid;
  border-right-style: solid;
  border-left-style: none;
  border-top-style: none;
  border-bottom-style: none;
  padding-right: 8px;
}

.stock-gainerslosers-item-right {
  float: left;
  margin-left: 6px;
  border-width: 1px;
  border-color: lightgray;
}

.stock-gainers-title,
.stock-losers-title {
  color: green;
  font-size: 10px;
  text-align: center;
  margin-bottom: 2px;
  font-weight: 600;
  letter-spacing: 1px;
}

.stock-losers-title {
  color: red;
}

.stock-chart-period-performance {
  float: left;
  /* color: black; */
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  font-weight: 500;
}

.stock-chart-period-average {
  float: right;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  /* background-color: rgb(236, 236, 236); */
}

.stock-symbol-chart-wrap {
  margin-left: -15px;
  margin-right: -5px;
}

.stock-watch-list-wrapper {
  text-align: center;
  justify-content: "center";
  margin-top: 0px;
  margin-bottom: 4px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  padding-left: 5px;
}

/* .stock-watch-list-blocks {
  margin: 4px;
  display: grid;
  grid-template-columns: 50% 50%;
} */

.stock-watch-list-item {
  font-size: 12px;
  margin-right: 4px;
  float: left;
  padding: 3px;
  background-color: #f3f3f3;
  border: #649e4c;
  border-width: 0px;
  border-radius: 8px;
  border-bottom-width: 2.5px;
  border-bottom-style: dotted;
}

.stock-watch-list-title {
  font-size: 10px;
  justify-content: center;
  text-align: center;
  color: rgb(111, 111, 111);
  margin: 0px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.stock-gainlose-item {
  font-size: 12px;
  border-bottom: solid;
  border-width: 0.1px;
  border-color: rgb(226, 226, 226);
  color: #2c3e50;
  letter-spacing: -0.2px;
}

.stock-option-tag,
.stock-earnings-item-tag,
.stock-earnings-item-tag-today,
.stock-earnings-item-tag-tomorrow,
.stock-earnings-item-tag-yesterday,
.stock-earnings-item-tag-thisMonth,
.stock-earnings-item-tag-nextMonth,
.stock-earnings-item-tag-thisWeek,
.stock-earnings-item-tag-nextWeek,
.stock-earnings-tag,
.stock-dividend-tag {
  text-align: center;
  font-weight: 500;
  font-size: 9.5px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: rgb(204, 150, 0);
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 2px;
  color: white;
  margin-right: 2px;
}

.stock-earnings-tag {
  background-color: rgb(204, 150, 0);
}

.stock-dividend-tag {
  background-color: rgb(12, 168, 59);
}

.stock-earnings-item-tag {
  font-size: 11px;
}

.stock-earnings-item-tag-today {
  font-size: 11px;
  background-color: #649e4c;
}

.stock-earnings-item-tag-tomorrow,
.stock-earnings-item-tag-thisWeek {
  font-size: 11px;
  background-color: #466f34;
}

.stock-earnings-item-tag-yesterday,
.stock-earnings-item-tag-thisMonth,
.stock-earnings-item-tag-nextMonth,
.stock-earnings-item-tag-nextWeek {
  font-size: 11px;
  background-color: #9f9f9f;
}

select.stock-account option.stock-account {
  background-color: #007700;
  border-radius: "3px";
  border-color: "lightgrey";
  width: "98%";
  justify-content: "center";
  text-align: "center";
}

.general-button,
.general-button-small,
.general-button-small-cancel {
  border-radius: 3px;
  background-color: #34495e;
  color: white;
  font-size: 14px;
  padding: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  width: 90%;
  margin: 5px;
  /* float: left; */
}

.general-button-small,
.general-button-small-cancel {
  width: 80%;
}

.general-button-small-cancel {
  background-color: darkred;
}
.stock-account-input {
  padding: 2px;
  width: 100%;
  border-radius: 4px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  font-size: 14px;
}

.bottons-divider {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 4px;
}

.stock-account-table {
  font-size: 12px;
  font-weight: 400;
  padding: 6px;
  margin: 6px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.stock-account-table tr {
  line-height: 18px;
}

.stock-account-table td {
  padding: 2px;
}

.stock-account-form-row {
  padding: 4px;
  margin: 4px;
}
.stock-account-form-edit {
  background-color: #ebebeb;
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
}

.stock-account-tag,
.stock-account-tag-off {
  background-color: hsl(93, 67%, 38%);
  border-radius: 3px;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin: 3px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
}

.stock-account-tag-off {
  background-color: #b8b8b8;
  color: rgb(126, 126, 126);
  font-weight: 600;
}

.stock-account-wrapper {
  justify-content: center;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  /* position: sticky;
  top: 82px;
  z-index: 20; */
}

.stock-tradex-stats {
  text-align: center;
  /* background-color: #34495e; */
  /* background-color: #e5e5e5; */
  background-color: rgb(243, 243, 243);
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  color: rgb(59, 59, 59);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  padding: 6px;
  margin: 6px;
  /* letter-spacing: 0.8px; */
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 4px;
}
.stock-earnings-wrapper {
  margin: 0px;
  margin-bottom: 2px;
  padding: 2px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 4px;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.stock-earnings-item {
  float: left;
  font-size: 12px;
  margin-right: 2px;
  padding: 3px;
}

.stock-earnings-div {
  /* float: left; */
  width: max-content;
  margin: 0px;
}

.stock-earnings-day-title {
  text-transform: uppercase;
  color: #6e6e6e;
  font-size: 8px;
  font-weight: 800;
  letter-spacing: 0.6px;
  margin: 0px;
}

.stock-watch-list-priority,
.stock-watch-list-sell,
.stock-watch-list-buy {
  margin: 0px;
  font-size: 8px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 2px;
  letter-spacing: 0.2px;
  padding-left: 2px;
  padding-right: 1px;
}

.stock-watch-list-sell {
  padding-left: 2px;
  padding-right: 2px;
  color: #649e4c;
}

.stock-watch-list-buy {
  padding-left: 2px;
  padding-right: 2px;
  color: red;
}

.nav-durations {
  margin: 0px;
  margin-top: 10px;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #868686;
  display: grid;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
}

.nav-plotter {
  grid-template-columns: 50% 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 4px;
  padding: 2px;
}

.nav-plotter-item,
.nav-plotter-item-active {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0.5px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 2px;
  color: black;
  border-radius: 4px;
  width: max-content;
  border: gray;
  border-width: 1px;
  border-style: solid;
}

.nav-plotter-item-active {
  color: white;
  /* color: #008FFB; */
  background-color: black;
}

.stock-options-tag,
.stock-options-tag-active {
  color: rgb(134, 134, 134);
  text-align: center;
  font-weight: 500;
  font-size: 9.5px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: rgb(227, 227, 227);
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 2px;
  margin-left: 1px;
  margin-right: 1px;
}

.stock-options-tag-active {
  color: white;
  background-color: #34495e;
}

.stock-nyse-time {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.stock-bls-chart-range,
.stock-bls-chart-range-active {
  margin: 5px;
  font-size: 12px;
  font-weight: 400;
}

.stock-bls-chart-range-active {
  font-weight: 600;
  text-decoration: underline;
}

.stock-table-mode-wrapper {
  display: flex;
  justify-content: right;
  margin-top: 8px;
  margin-bottom: 4px;
}

.stock-table-mode,
.stock-table-mode-active {
  color: rgb(176, 176, 176);
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: rgb(227, 227, 227);
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  width: 50px;
}

.stock-table-mode-active {
  color: white;
  background-color: #34495e;
}

.stock-wide-info,
.stock-wide-info-narrow,
.stock-wide-info-narrow-bold {
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  font-weight: 400;
  color: rgb(127, 127, 127);
}

.stock-wide-info-narrow {
  letter-spacing: -0.8px;
}

.stock-wide-info-narrow-bold {
  text-transform: uppercase;
  color: black;
  font-weight: 600;
  font-size: 11.5px;
  letter-spacing: -0.5px;
}
.stock-table-value-center {
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}

.stock-group-setter,
select:active {
  text-transform: uppercase;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 3px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 700;
  border-color: rgb(105, 105, 105);
  padding: 0px;
  width: 80%;
  justify-content: center;
  text-align: center;
  background-color: white;
  height: 18px;
  outline: none;
}

.stock-table-panel {
  display: grid;
  grid-template-columns: 50% 50%;
}

.stock-ern-div-wrapper {
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.stock-info-add-to-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 6px;
  margin-right: 9px;
}
.stock-info-add-to-group-button {
  background-color: black;
  font-weight: 600;
  text-transform: uppercase;
  color: yellow;
  font-size: 11px;
  border-radius: 3px;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 4px;
}

.stock-event-bar {
  margin: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 2px;
  padding-left: 4px;

  background-color: rgb(243, 243, 243);
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  color: rgb(59, 59, 59);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}

.nav-chart-period-performance {
  margin: 4px;
  margin-bottom: 0px;
  padding: 4px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
}

.stock-search-icons {
  /* margin-bottom: -5px; */
  margin-left: -8px;
  margin-right: -8px;
  padding: 0px;
  font-size: 24px;
  color: rgb(121, 121, 121);
}

.stock-news-title:nth-child(even) {
  background-color: rgb(243, 243, 243);
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 12px;
  padding: 4px;
  border-radius: 5px;
}

.stock-news-title:nth-child(odd) {
  background-color: white;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 12px;
  padding: 4px;
}

.stock-news-summary {
  font-weight: 400;
  font-size: 14px;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(64, 64, 64);
}

.bucket-item {
  justify-content: "center";
  margin: 5px;
  margin-bottom: 8px;
  padding: 4px;
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
  max-width: 500px;
  min-width: 400px;
}

.stock-top-icons {
  margin: 0px;
  padding: 0px;
  margin-top: -9px;
  font-size: 9px;
  text-transform: uppercase;
}

.assets-top-stats {
  width: 100%;
  grid-template-columns: 20% 20% 20% 20% 20%;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  color: #9f9f9f;
}

.assets-top-stats-item {
  margin: 6px;
  font-size: 12px;
}

.assets-top-stats-item-value {
  font-weight: 500;
  font-size: 18px;
  color: black;
}

.stock-top-stock-percent-selector {
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.fire-stats {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  text-align: center;
  font-size: 26px;
  border: 0px;
  border-right: 1px;
  border-color: rgb(196, 196, 196);
  border-style: solid;
  color: rgb(121, 121, 121);
  padding: 10px;
  margin-top: 10px;
}
