/* ion-content ion-toolbar {
  --background: translucent;
} */

.the-top-container {
  max-width: 1024px;
  justify-content: center;
  margin: 6px;
  margin: auto;
  border-width: 0px;
  border-right-color: rgb(228, 228, 228);
  border-left-color: rgb(228, 228, 228);
  border-style: solid;
  box-shadow: 1px 0px 11px -1px rgba(84, 84, 84, 0.47);
  padding-bottom: 6px;
}

.account-header {
  padding: 10px;
  background-color: #c0392b;
}

.account-title {
  margin-top: 2px;
  margin-bottom: 1px;
  text-align: center;
  font-weight: 800;
  color: white;
  font-size: 32px;
}

.account-subtitle {
  /* margin-top: s */
  margin-bottom: 5px;
  /* position: relative; */
  text-align: center;
  font-weight: 420;
  color: white;
  letter-spacing: 4px;
  font-size: 14px;
}

.account-info {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 5px;
  background-color: #34495e;
  border-radius: 5px;
}

.account-info-wrapper {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(1, 80%);
}

.account-info-title {
  margin-bottom: 2px;
  padding: 3px;
  border-radius: 3px;
  background-color: #273646;
  text-align: center;
  font-weight: 450;
  color: white;
  letter-spacing: 2px;
  font-size: 14px;
}

.account-info-item {
  padding: 2px;
  color: rgb(255, 255, 255);
  font-size: 14px;
}
