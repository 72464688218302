/* ion-content ion-toolbar {
  --background: translucent;
} */

/* FOR IOS */
/* ion-content {
  --padding-top: 32px;
} */

.home-header {
  /* margin-top: 32px; */
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 4px;
  background-color: #c0392b;
  position: sticky;
  top: 0px; /* Don't forget this, required for the stickiness */
  z-index: 10;
  box-shadow: 0px 4px 4px -3px rgba(105, 105, 105, 1);
}

ion-tab-button {
  /* max-width: 58px; */
  margin-left: -20px;
  margin-right: -20px;
  padding-left: -10px;
  padding-right: -10px;
}

.home-title {
  margin-top: 2px;
  margin-bottom: 1px;
  /* position: relative; */
  text-align: center;
  font-weight: 800;
  color: white;
  /* letter-spacing: px; */
  font-size: 32px;
}

.home-block {
  display: grid;
  grid-template-columns: 5% 90% 5%;
}

.home-block-header {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 12px;
  padding: 4px;
  background-color: rgb(243, 243, 243);
  letter-spacing: 0.6px;
  font-weight: 500;
}

.account-subtitle {
  /* margin-top: s */
  margin-bottom: 5px;
  /* position: relative; */
  text-align: center;
  font-weight: 420;
  color: white;
  letter-spacing: 4px;
  font-size: 14px;
}

.account-info {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 5px;
  background-color: #34495e;
  border-radius: 5px;
}

.account-info-wrapper {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(1, 80%);
}

/* .account-info-title {
  margin-bottom: 2px;
  padding: 3px;
  border-radius: 5px;
  background-color: #273646;
  text-align: center;
  font-weight: 450;
  color: white;
  letter-spacing: 4px;
  font-size: 10px;
} */

.account-info-item {
  padding: 2px;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.section-header {
  margin-top: 5px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: rgb(146, 146, 146);
  background-color: rgb(243, 243, 243);
  letter-spacing: 1px;
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  border-radius: 5px;
  border: 0px;
  border-top: 2px;
  border-color: rgb(230, 230, 230);
  border-style: solid;
}

.item-value {
  font-weight: 300;
  font-size: 18px;
  /* letter-spacing: -1px; */
}

/* #chart-dca {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
} */

.dca-interval,
.dca-day {
  margin-top: 2px;
  font-size: 8px;
  float: right;
  font-weight: 500;
  background-color: grey;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  text-transform: uppercase;
}

.dca-interval {
  float: left;
}

/* TO DELETE */
.dca-progress-bar {
  content: "";
  /* position: absolute; */
  /* left    : 0; */
  /* top  : 0px; */
  height: 1px;
  width: 100%; /* percentage of the element width*/
  border-bottom: 4px solid green;
  margin-top: 1.2em; /*move the border below the text*/
}

.fire-item-header {
  background-color: rgb(244, 244, 244);
  margin: 0px;
  padding: 4px;
  /* border-radius: 5px;
  border-width: 3px; */
  /* transform: rotate(90deg); */
}

.fire-item-header-title {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 4px;
}

.fire-item-header-tag,
.fire-item-header-tag-active,
.fire-item-header-tag-wrate,
.fire-item-header-tag-clone,
.fire-item-header-tag-edit {
  border-width: 2px;
  border-radius: 3px;
  padding-left: 4.5px;
  padding-right: 4.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 5px;
  text-transform: uppercase;
  background-color: rgb(146, 146, 146);
  font-size: 10px;
  color: white;
}

.fire-item-header-tag-active {
  background-color: hsl(93, 67%, 38%);
  font-weight: 600;
}

.fire-item-header-tag-wrate {
  background-color: hsl(43, 100%, 42%);
  font-weight: 600;
}

.fire-item-header-tag-clone {
  background-color: #34495e;
}

.fire-item-header-tag-edit {
  background-color: #747474;
}

.fire-item-header-desc {
  font-size: 12px;
  padding-left: 4px;
  text-transform: none;
  color: gray;
  font-weight: 400;
}

.fire-item.header-total-amount {
  font-weight: 500;
  float: "right";
  font-size: "18px";
}

.fire-withPercent-wrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: rgb(77, 77, 77);
  margin-bottom: 6px;
}

.fire-withPercent-item-active {
  color: hsl(93, 100%, 26%);
  font-weight: 600;
  font-size: 13px;
  background-color: rgb(244, 244, 244);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 4px;
  border: 0px;
  border-bottom: 2px;
  /* border-color: rgb(173, 173, 173); */
  border-color: hsl(93, 67%, 38%);
  border-style: solid;
}

.investments-item-wrapper {
  background-color: rgb(243, 243, 243);
  margin: 6px;
  border-radius: 6px;
  padding: 4px;
  padding-bottom: 10px;
}

.investments-item-name {
  font-size: 16px;
  font-weight: 350;
}
.investments-item-desc {
  font-size: 11px;
  text-transform: none;
  color: gray;
  font-weight: 400;
}

.investments-item-type {
  border-width: 2px;
  border-radius: 3px;
  padding-left: 4.5px;
  padding-right: 4.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: uppercase;
  background-color: hsl(198, 100%, 28%);
  font-size: 10px;
  color: white;
}

.home-side-percentage,
.home-side-percentage2 {
  text-align: center;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 1.6rem;
  text-size-adjust: 100%;
  padding: 4px;
  /* background-color: rgb(243, 243, 243); */
  letter-spacing: 1px;
  font-weight: 900;
  color: rgb(212, 212, 212);
  height: 220px;
}

.home-side-percentage2 {
  letter-spacing: 0px;
}

.home-percentage-block {
  display: grid;
  grid-template-columns: 5% 90% 5%;
}

.home-app-version {
  color: white;
  font-size: 12px;
  letter-spacing: -0.2px;
}

.home-secondary-block {
  z-index: 2;
}

.home-fire-charts-wrapper-1,
.home-fire-charts-wrapper-2,
.home-fire-charts-wrapper-3 {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.home-fire-charts-wrapper-2 {
  grid-template-columns: 50% 50%;
}

.home-fire-charts-wrapper-3 {
  grid-template-columns: 33% 33% 33%;
}

.fire-plan-type-main,
.fire-plan-type-alt {
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  font-weight: 500;
  color: black;
  font-size: 12px;
}

.fire-plan-type-alt {
  font-weight: 400;
  color: rgb(106, 106, 106);
}

.gauge-container {
  width: 300px;
  height: 300px;
  position: relative;
}

.fire-spending-type,
.fire-income-type {
  border-width: 2px;
  border-radius: 3px;
  padding-left: 4.5px;
  padding-right: 4.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 5px;
  text-transform: uppercase;
  background-color: rgb(173, 0, 0);
  font-size: 10px;
  color: white;
  float: right;
  padding-right: 10px;
}
.fire-income-type {
  background-color: rgb(89, 173, 0);
}
